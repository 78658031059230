import http from './http';

// 发送邮件
export async function sendEmail(params) {
    const res = await http.post(
        'http://jxctest.kuguanwang.com/api/services/app/sysAccount/applyExp',
        params
    );
    return res;
}

// 发送验证码
export async function sendVerify(userMobile) {
    const res = await http.get(`/api/services/app/SysAccount/Verify?userMobile=${userMobile}`);
    return res;
}

// 立即体验，用来做提交验证
export async function getExperience(params) {
    const res = await http.post('/api/services/app/SysAccount/Experience', params);
    return res;
}
