import React, { useState } from 'react';
import { Link } from 'gatsby';
import Form from 'antd/es/form';
import Input from 'antd/es/input';
import message from 'antd/es/message';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';

import Content from '../components/content';
import ExperienceModal from '../components/modal';
import footerStyles from '../styles/footer.module.less';

import { sendEmail } from '../api/server';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 24 }
};

export default () => {
    const [showModal, setShowModal] = useState(false);

    const handleCancel = () => {
        setShowModal(false);
    };
    const [loading, setLoading] = useState(false);
    const onFinish = async (values) => {
        setLoading(true);
        const queryParams = {
            clientName: values.storeName,
            fullName: values.userName,
            mobile: values.userPhone,
            to: 'wangyang@guojing.net',
            productName: '店货通',
            email: values.userEmail,
            position: '',
            size: '',
            content: values.messageContent
        };
        const res = await sendEmail(queryParams);
        if (res) {
            message.info('提交成功');
        }
        setLoading(false);
    };

    return (
        <>
            <Content className={footerStyles.freeTrialForm}>
                <div className={footerStyles.freeTrialForm__content}>
                    <h4>立即获取免费试用资格</h4>
                    <Form
                        {...layout}
                        size="large"
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                    >
                        <Row gutter={[10, 0]}>
                            <Col span={18}>
                                <Row gutter={[10, 0]}>
                                    <Col span={12}>
                                        <Form.Item
                                            name="userName"
                                            rules={[{ required: true, message: '请输入姓名!' }]}
                                        >
                                            <Input placeholder="姓名" style={{ width: '100%' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            name="userPhone"
                                            rules={[{ required: true, message: '请输入手机号码!' }]}
                                        >
                                            <Input
                                                placeholder="手机号码"
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            name="storeName"
                                            rules={[{ required: true, message: '请输入店铺名称!' }]}
                                        >
                                            <Input
                                                placeholder="店铺名称"
                                                style={{ width: '100%' }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>

                            <Col span={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <Row
                                    style={{ display: 'flex', alignItems: 'center', width: '80%' }}
                                >
                                    <Col span={24} style={{ textAlign: 'left' }}>
                                        <Form.Item>
                                            <Button
                                                loading={loading}
                                                shape="round"
                                                style={{ width: '100%' }}
                                                htmlType="submit"
                                            >
                                                提交信息
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </Content>
            <footer className={footerStyles.appFooter__wrap}>
                <Content className={footerStyles.appFooter}>
                    <div className={footerStyles.appFooterLeft__wrap}>
                        <dl>
                            <dt>产品介绍</dt>
                            <dd>
                                <Link to="/">首页</Link>
                            </dd>
                            <dd>
                                <Link to="/product">产品介绍</Link>
                            </dd>
                            <dd>
                                <Link to="/service">服务保障</Link>
                            </dd>
                            <dd>
                                <Link to="/cooperate">渠道合作</Link>
                            </dd>
                            <dd>
                                <Link to="/about">关于我们</Link>
                            </dd>
                            <dd>
                                <Link to="/contact">联系我们</Link>
                            </dd>
                        </dl>
                        <dl>
                            <dt>友情链接</dt>
                            <dd>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.quanerp.com"
                                >
                                    小圈ERP
                                </a>
                            </dd>
                            <dd>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="http://www.kuguanwang.com"
                                >
                                    库管王
                                </a>
                            </dd>
                            <dd>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.jkucun.com"
                                >
                                    简库存
                                </a>
                            </dd>
                            <dd>
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.gendanwang.com"
                                >
                                    跟单王
                                </a>
                            </dd>
                        </dl>
                        <dl>
                            <dt>更多内容</dt>
                            <dd>
                                <Link to="/cooperate">渠道合作</Link>
                            </dd>
                            <dd>
                                <Link to="/about">关于我们</Link>
                            </dd>
                            <dd>
                                <Link to="/contact">联系我们</Link>
                            </dd>
                        </dl>
                        <dl>
                            <dt>联系我们</dt>
                            <dd>电话：400-000-2900</dd>
                            <dd>邮箱：marketing@kuguanwang.com</dd>
                            <dd>
                                网址：
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href="https://www.dianerp.com"
                                >
                                    https://www.dianerp.com
                                </a>
                            </dd>
                            <dd>地址：上海市普陀区常德路1339号</dd>
                        </dl>
                    </div>
                    <div className={footerStyles.appFooterRight__wrap}>
                        <h4>让企业库存管理更简单</h4>
                        <p>来店货通，提升企业效率的管理软件</p>
                        <button onClick={() => setShowModal(true)}>立即体验</button>
                    </div>
                </Content>
                <Content className={footerStyles.appFooterRecord}>
                    <p>
                        <a
                            href="http://www.beian.miit.gov.cn"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            沪ICP备16015734号-8
                        </a>
                    </p>
                </Content>

                <ExperienceModal showModal={showModal} onCancel={handleCancel} />
            </footer>
        </>
    );
};
