import React, { useState } from 'react';
import FixStyles from '../styles/fix.module.less';
import Icon from '../components/icon';
import { BackTop } from 'antd';

import ExperienceModal from '../components/modal';

export default () => {
    const [showModal, setShowModal] = useState(false);

    const handleCancel = () => {
        setShowModal(false);
    };

    return (
        <>
            <ul className={FixStyles.fixWindow}>
                <li>
                    <a rel="noopener noreferrer" href="http://q.url.cn/cdPt4e?_type=wpa&qidian=true" target="_blank">
                        <Icon type="iconkefu" style={{ fontSize: '40px', color: '#fff' }} />
                        <p>在线客服</p>
                    </a>
                </li>
                <li>
                    <Icon type="icondianhua" style={{ fontSize: '40px', color: '#fff' }} />
                    <p>
                        联系电话
                    </p>
                    <i className={FixStyles.fixWindow__phone}>400-000-2900</i>
                </li>
                <li>
                    <button onClick={() => setShowModal(true)} style={{background: '#4e74ef', cursor: 'pointer'}}>
                        <Icon type="iconxiaolian" style={{ fontSize: '40px', color: '#fff' }} />
                        <p>立即体验</p>
                    </button>
                </li>
                <li>
                    <BackTop visibilityHeight={-10}>
                        <Icon type="iconfanhuidingbu" style={{ fontSize: '40px', color: '#fff' }} />
                        <p>返回顶部</p>
                    </BackTop>
                </li>
            </ul>
            <ExperienceModal showModal={showModal} onCancel={handleCancel} />
        </>
    );
};
