import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import Button from 'antd/es/button';

import Icon from '../components/icon';

import headerStyles from '../styles/header.module.less';
import Fixwindow from '../components/fixwindow';
import androidImage from '../images/android.png';

const navs = [
    {
        text: '首页',
        to: '/home/'
    },
    {
        text: '产品介绍',
        to: '/product/'
    },
    {
        text: '服务优势',
        to: '/service/'
    },
    {
        text: '渠道合作',
        to: '/cooperate/'
    },
    {
        text: '关于我们',
        to: '/about/'
    },
    {
        text: '联系我们',
        to: '/contact/'
    }
];

export default () => {
    const [sticky, setSticky] = useState('');
    useEffect(() => {
        window.addEventListener('scroll', () => {
            const t = document.querySelector('body, html').scrollTop;
            
            if (t > 0) {
                if (sticky === '') setSticky(headerStyles.header__sticky);
            } else {
                setSticky('');
            }
        });
    }, [sticky]);

    return (
        <header className={`${headerStyles.header} ${sticky}`}>
            <span className={headerStyles.headerLogo}>
                <Icon type="iconweibiaoti-3" />
                店货通
            </span>
            {navs.map((nav, index) => (
                <Link
                    key={index}
                    to={nav.to}
                    className={headerStyles.navItem}
                    activeClassName={headerStyles.active}
                >
                    {nav.text}
                </Link>
            ))}
            <span className={headerStyles.navItem}>
                APP下载
                <ul className={headerStyles.navItem__dialog}>
                    <li>
                        <p className={headerStyles.navItem__dialog__word}>
                            <Icon type="iconanzhuo" style={{ fontSize: '30px', color: '#888' }} />
                            <span>Android下载</span>
                        </p>
                        <p className={headerStyles.navItem__dialog__image}>
                            <img src={androidImage} alt="" wdith="100%" height="100%" />
                        </p>
                    </li>
                </ul>
            </span>
            <Button type="primary" style={{ margin: '0 20px' }}>
                <a target="_blank" rel="noopener noreferrer" href="https://dianerp.com/#/login">
                    登录
                </a>
            </Button>
            <Button type="primary">
                <a target="_blank" rel="noopener noreferrer" href="https://dianerp.com/#/login">
                    注册
                </a>
            </Button>

            <Fixwindow />
        </header>
    );
};
