import React from 'react';
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_1662287_aqdk65wughk.js'
});

export default (props) => {
    return <IconFont className="iconfont" {...props}  />;
};
